.hi-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: sub;
    margin-right: .25rem !important;
}

.hi-1_25x {
    width: 23px;
    height: 23px;
}

.hi-1_5x {
    width: 27px;
    height: 27px;
}

.hi-2x {
    width: 36px;
    height: 36px;
}

.hi-3x {
    width: 54px;
    height: 54px;
}

.hi-4x {
    width: 72px;
    height: 72px;
}

.hi-light {
    filter: invert(1);
    /*  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg); */
}

.filter-red {
    filter: brightness(0) saturate(100%) invert(20%) sepia(90%) saturate(7451%) hue-rotate(358deg) brightness(93%) contrast(107%);
}

.filter-white {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7461%) hue-rotate(296deg) brightness(105%) contrast(90%);
}

@keyframes rotateClockwise {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.hi-spin-effect {
    display: inline-block;
    /* Ensure the element respects the transform */
    animation: rotateClockwise 2s linear infinite;
    /* Rotate continuously */
}

@keyframes flip {
    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(180deg);
    }

    100% {
        transform: rotateY(360deg);
    }
}

.hi-flip-effect {
    display: inline-block;
    /* Ensure the element respects the transform */
    animation: flip 2s linear infinite;
    /* Apply the flip animation */
    transform-style: preserve-3d;
    /* Preserve 3D space for the flip effect */
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}

.hi-bounce-effect {
    display: inline-block;
    /* Ensure the element respects the transform */
    animation: bounce 2s infinite;
    /* Apply the bounce animation */
}

@keyframes fade {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

.hi-fade-effect {
    display: inline-block;
    /* Ensure the element respects the transform */
    animation: fade 1s infinite;
    /* Apply the fade animation */
}

@keyframes beatFade {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: scale(1);
        opacity: 1;
    }

    40% {
        transform: scale(1.1);
        opacity: 0.25;
    }

    60% {
        transform: scale(1.05);
        opacity: 0.75;
    }
}

.hi-beat-fade-effect {
    display: inline-block;
    /* Ensure the element respects the transform */
    animation: beatFade 2s infinite;
    /* Apply the beat-fade animation */
}


@keyframes beat {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: scale(1);
    }

    40% {
        transform: scale(1.1);
    }

    60% {
        transform: scale(1.05);
    }
}

.hi-beat-effect {
    display: inline-block;
    /* Ensure the element respects the transform */
    animation: beat 1s infinite;
    /* Apply the beat animation */
}

@keyframes shake {

    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-3px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(3px);
    }
}

.hi-shake-effect {
    display: inline-block;
    /* Ensure the element respects the transform */
    animation: shake 1s infinite;
    /* Apply the shake animation */
}